<template>
  <div class="interstellartext full-width q-ma-none q-pa-none">
    <svg>
      <symbol :id="id">
        <text text-anchor="middle" x="50%" y="50%" dy="0.35em">
          <slot>
            <span v-html="content"></span>
          </slot>
        </text>
      </symbol>
      <use class="text" :xlink:href="'#' + id"></use>
      <use class="text" :xlink:href="'#' + id"></use>
      <use class="text" :xlink:href="'#' + id"></use>
      <use class="text" :xlink:href="'#' + id"></use>
      <use class="text" :xlink:href="'#' + id"></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: "interstellartext",
  props: {
    content: {
      type: String,
      default: "InterstellarText",
    },
    id: {
      type: String,
      default: "id",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 100%;
  max-height: 40px;
}

// $colors: #F2385A, #F5A503, #E9F1DF, #56D9CD, #3AA1BF;
$colors: rgba(255, 255, 255, 0.3), rgba(0, 27, 88, 0.3);
$max: length($colors);
$dash: 500; // defaul 70
$dash-gap: 10;
$dash-space: $dash * ($max - 1) + $dash-gap * $max;
$time: 6s;
$time-step: $time/$max;

/* Main styles */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:800);

.text {
  fill: none;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-dasharray: $dash $dash-space;
  stroke-dashoffset: 0;
  -webkit-animation: stroke $time infinite linear;
  animation: stroke $time infinite linear;

  @for $item from 1 through $max {
    &:nth-child(#{$max}n + #{$item}) {
      $color: nth($colors, $item);
      stroke: $color;
      -webkit-animation-delay: -($time-step * $item);
      animation-delay: -($time-step * $item);
    }
  }
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -($dash + $dash-gap) * $max;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -($dash + $dash-gap) * $max;
  }
}
</style>
